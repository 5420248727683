var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");
'use strict';var Ys,uda,vda,at,wda,dt,et;
$CLJS.Xs=function(a,b,c){return function(){function d(t,u,v){return a.M?a.M(b,c,t,u,v):a.call(null,b,c,t,u,v)}function e(t,u){return a.v?a.v(b,c,t,u):a.call(null,b,c,t,u)}function f(t){return a.j?a.j(b,c,t):a.call(null,b,c,t)}function k(){return a.g?a.g(b,c):a.call(null,b,c)}var l=null,m=function(){function t(v,x,A,C){var G=null;if(3<arguments.length){G=0;for(var J=Array(arguments.length-3);G<J.length;)J[G]=arguments[G+3],++G;G=new $CLJS.w(J,0,null)}return u.call(this,v,x,A,G)}function u(v,x,A,C){return $CLJS.Ne.l(a,
b,c,v,x,$CLJS.H([A,C]))}t.A=3;t.B=function(v){var x=$CLJS.z(v);v=$CLJS.B(v);var A=$CLJS.z(v);v=$CLJS.B(v);var C=$CLJS.z(v);v=$CLJS.Hc(v);return u(x,A,C,v)};t.l=u;return t}();l=function(t,u,v,x){switch(arguments.length){case 0:return k.call(this);case 1:return f.call(this,t);case 2:return e.call(this,t,u);case 3:return d.call(this,t,u,v);default:var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.l(t,u,v,A)}throw Error("Invalid arity: "+
arguments.length);};l.A=3;l.B=m.B;l.o=k;l.h=f;l.g=e;l.j=d;l.l=m.l;return l}()};Ys=function(a,b){return $CLJS.E.g(a,b)?new $CLJS.P(null,3,5,$CLJS.Q,[null,null,a],null):new $CLJS.P(null,3,5,$CLJS.Q,[a,b,null],null)};uda=function(a){return $CLJS.y(a)?$CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return $CLJS.R.j(b,d,c)},$CLJS.yf($CLJS.Ze($CLJS.Ne.g($CLJS.fk,$CLJS.Ng(a)),null)),a):null};
vda=function(a,b,c){var d=$CLJS.K.g(a,c),e=$CLJS.K.g(b,c),f=$CLJS.Zs(d,e),k=$CLJS.I(f,0,null),l=$CLJS.I(f,1,null);f=$CLJS.I(f,2,null);a=$CLJS.Dd(a,c);b=$CLJS.Dd(b,c);d=a&&b&&(null!=f||null==d&&null==e);return new $CLJS.P(null,3,5,$CLJS.Q,[!a||null==k&&d?null:$CLJS.Ae([c,k]),!b||null==l&&d?null:$CLJS.Ae([c,l]),d?$CLJS.Ae([c,f]):null],null)};
at=function(a,b){var c=$CLJS.cf.g,d=$s.j,e=$CLJS.vd(a)?a:$CLJS.yf(a),f=$CLJS.vd(b)?b:$CLJS.yf(b);a=$CLJS.D(a);b=$CLJS.D(b);return $CLJS.yf(c.call($CLJS.cf,uda,d.call($s,e,f,$CLJS.bh(0,a>b?a:b))))};wda=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Be($CLJS.bt.g(a,b)),$CLJS.Be($CLJS.bt.g(b,a)),$CLJS.Be($CLJS.ct.g(a,b))],null)};
dt=function(a){if(null!=a&&null!=a.ih)a=a.ih(a);else{var b=dt[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=dt._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.$a("EqualityPartition.equality-partition",a);}return a};et=function(a,b){if(null!=a&&null!=a.hh)a=a.hh(a,b);else{var c=et[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=et._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("Diff.diff-similar",a);}return a};
$CLJS.Zs=function(a,b){return $CLJS.E.g(a,b)?new $CLJS.P(null,3,5,$CLJS.Q,[null,null,a],null):$CLJS.E.g(dt(a),dt(b))?et(a,b):Ys(a,b)};$CLJS.ct=function ct(a){switch(arguments.length){case 1:return ct.h(arguments[0]);case 2:return ct.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return ct.l(arguments[0],arguments[1],new $CLJS.w(c.slice(2),0,null))}};$CLJS.ct.h=function(a){return a};
$CLJS.ct.g=function(a,b){for(;;)if($CLJS.D(b)<$CLJS.D(a)){var c=a;a=b;b=c}else return $CLJS.cb(function(d,e){return function(f,k){return $CLJS.Dd(e,k)?f:$CLJS.ck.g(f,k)}}(a,b),a,a)};$CLJS.ct.l=function(a,b,c){a=$CLJS.Vs(function(d){return-$CLJS.D(d)},$CLJS.Yd.l(c,b,$CLJS.H([a])));return $CLJS.cb($CLJS.ct,$CLJS.z(a),$CLJS.Hc(a))};$CLJS.ct.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.ct.A=2;
$CLJS.bt=function bt(a){switch(arguments.length){case 1:return bt.h(arguments[0]);case 2:return bt.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return bt.l(arguments[0],arguments[1],new $CLJS.w(c.slice(2),0,null))}};$CLJS.bt.h=function(a){return a};$CLJS.bt.g=function(a,b){return $CLJS.D(a)<$CLJS.D(b)?$CLJS.cb(function(c,d){return $CLJS.Dd(b,d)?$CLJS.ck.g(c,d):c},a,a):$CLJS.cb($CLJS.ck,a,b)};
$CLJS.bt.l=function(a,b,c){return $CLJS.cb($CLJS.bt,a,$CLJS.Yd.g(c,b))};$CLJS.bt.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.bt.A=2;var ft=new $CLJS.M(null,"atom","atom",-397043653);var $s=function $s(a){switch(arguments.length){case 2:return $s.g(arguments[0],arguments[1]);case 3:return $s.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$s.g=function(a,b){return $s.j(a,b,$CLJS.Ws.g($CLJS.Ng(a),$CLJS.Ng(b)))};$s.j=function(a,b,c){return $CLJS.cb(function(d,e){return $CLJS.ch($CLJS.cf.j($CLJS.Ak,d,e))},new $CLJS.P(null,3,5,$CLJS.Q,[null,null,null],null),$CLJS.cf.g($CLJS.Xs(vda,a,b),c))};$s.A=3;
dt["null"]=function(){return ft};dt.string=function(){return ft};dt.number=function(){return ft};dt.array=function(){return $CLJS.cj};dt["function"]=function(){return ft};dt["boolean"]=function(){return ft};
dt._=function(a){return(null!=a?a.C&1024||$CLJS.yc===a.Wg||(a.C?0:$CLJS.Ya($CLJS.zb,a)):$CLJS.Ya($CLJS.zb,a))?$CLJS.Cj:(null!=a?a.C&4096||$CLJS.yc===a.$g||(a.C?0:$CLJS.Ya($CLJS.Db,a)):$CLJS.Ya($CLJS.Db,a))?$CLJS.Ji:(null!=a?a.C&16777216||$CLJS.yc===a.qf||(a.C?0:$CLJS.Ya($CLJS.Tb,a)):$CLJS.Ya($CLJS.Tb,a))?$CLJS.cj:ft};et["null"]=function(a,b){return Ys(a,b)};et.string=function(a,b){return Ys(a,b)};et.number=function(a,b){return Ys(a,b)};et.array=function(a,b){return at(a,b)};
et["function"]=function(a,b){return Ys(a,b)};et["boolean"]=function(a,b){return Ys(a,b)};et._=function(a,b){var c=function(){var d=dt(a);d=d instanceof $CLJS.M?d.S:null;switch(d){case "atom":return Ys;case "set":return wda;case "sequential":return at;case "map":return $s;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}();return c.g?c.g(a,b):c.call(null,a,b)};