var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var OI;$CLJS.MI=function(a){return $CLJS.n($CLJS.qB($CLJS.sd,$CLJS.rd,$CLJS.Yk)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.NI=function(a,b){return $CLJS.Me($CLJS.Ad,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(J,S,X,W){return function(){for(var da=C;;){var ra=$CLJS.y(da);if(ra){var Ma=ra,sb=$CLJS.z(Ma);if(ra=$CLJS.y(function(Qa,Xa,Ja,Va,Ta,mb,dc,ed){return function Rh(hd){return new $CLJS.ie(null,function(rz,Jl,Wn,Ar,T_,Nv){return function(){for(;;){var Ov=
$CLJS.y(hd);if(Ov){if($CLJS.wd(Ov)){var Pv=$CLJS.ic(Ov),sz=$CLJS.D(Pv),Fk=$CLJS.le(sz);a:for(var Br=0;;)if(Br<sz){var ht=$CLJS.id(Pv,Br);ht=$CLJS.E.g(Nv,$CLJS.YD)||$CLJS.E.g(Wn,$CLJS.YD)||$CLJS.zE(Nv,ht)&&$CLJS.zE(Wn,ht);Fk.add(ht);Br+=1}else{Pv=!0;break a}return Pv?$CLJS.oe($CLJS.qe(Fk),Rh($CLJS.jc(Ov))):$CLJS.oe($CLJS.qe(Fk),null)}Fk=$CLJS.z(Ov);return $CLJS.ae($CLJS.E.g(Nv,$CLJS.YD)||$CLJS.E.g(Wn,$CLJS.YD)||$CLJS.zE(Nv,Fk)&&$CLJS.zE(Wn,Fk),Rh($CLJS.Hc(Ov)))}return null}}}(Qa,Xa,Ja,Va,Ta,mb,dc,
ed),null,null)}}(da,J,sb,Ma,ra,S,X,W)($CLJS.jE)))return $CLJS.bf.g(ra,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.MI($CLJS.LD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.MI($CLJS.LD(a)))}())};
OI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.Ne.j($CLJS.vE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);f=$CLJS.ND;
return $CLJS.n(f)?f:$CLJS.Le($CLJS.il,$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.NI($CLJS.K.g(k,m),$CLJS.K.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.PI=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.QI=new $CLJS.M("operator","filter","operator/filter",-1518842858);
$CLJS.RI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var SI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lr,$CLJS.er],null)),TI=null,UI=0,VI=0;;)if(VI<UI){var Kia=TI.X(null,VI);$CLJS.yE(Kia,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)],null)],null)],null)]));VI+=1}else{var WI=$CLJS.y(SI);if(WI){var XI=WI;if($CLJS.wd(XI)){var YI=$CLJS.ic(XI),Lia=$CLJS.jc(XI),
Mia=YI,Nia=$CLJS.D(YI);SI=Lia;TI=Mia;UI=Nia}else{var Oia=$CLJS.z(XI);$CLJS.yE(Oia,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)],null)],null)],null)]));SI=$CLJS.B(XI);TI=null;UI=0}VI=0}else break}$CLJS.wE($CLJS.gr,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)]));
for(var ZI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.zF],null)),$I=null,aJ=0,bJ=0;;)if(bJ<aJ){var Pia=$I.X(null,bJ);$CLJS.yE(Pia,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.aE],null)],null)],null)],null)]));bJ+=1}else{var cJ=$CLJS.y(ZI);if(cJ){var dJ=cJ;if($CLJS.wd(dJ)){var eJ=$CLJS.ic(dJ),Qia=$CLJS.jc(dJ),
Ria=eJ,Sia=$CLJS.D(eJ);ZI=Qia;$I=Ria;aJ=Sia}else{var Tia=$CLJS.z(dJ);$CLJS.yE(Tia,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.aE],null)],null)],null)],null)]));ZI=$CLJS.B(dJ);$I=null;aJ=0}bJ=0}else break}
for(var fJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Eq,$CLJS.Gq,$CLJS.Aq,$CLJS.Cq],null)),gJ=null,hJ=0,iJ=0;;)if(iJ<hJ){var Uia=gJ.X(null,iJ);$CLJS.uE.l(OI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Uia,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null)]));iJ+=1}else{var jJ=$CLJS.y(fJ);if(jJ){var kJ=jJ;if($CLJS.wd(kJ)){var lJ=$CLJS.ic(kJ),Via=$CLJS.jc(kJ),Wia=lJ,Xia=$CLJS.D(lJ);fJ=Via;gJ=Wia;hJ=Xia}else{var Yia=
$CLJS.z(kJ);$CLJS.uE.l(OI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Yia,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null)]));fJ=$CLJS.B(kJ);gJ=null;hJ=0}iJ=0}else break}
$CLJS.uE.l(OI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.LE,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null)]));
$CLJS.uE.l(OI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.RE,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null)]));
for(var mJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KE,$CLJS.WE],null)),nJ=null,oJ=0,pJ=0;;)if(pJ<oJ){var Zia=nJ.X(null,pJ);$CLJS.wE(Zia,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XD],null)]));pJ+=1}else{var qJ=$CLJS.y(mJ);if(qJ){var rJ=qJ;if($CLJS.wd(rJ)){var sJ=$CLJS.ic(rJ),$ia=$CLJS.jc(rJ),aja=sJ,bja=$CLJS.D(sJ);mJ=$ia;nJ=aja;oJ=bja}else{var cja=$CLJS.z(rJ);$CLJS.wE(cja,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XD],null)]));mJ=$CLJS.B(rJ);
nJ=null;oJ=0}pJ=0}else break}
for(var tJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ME,$CLJS.TE],null)),uJ=null,vJ=0,wJ=0;;)if(wJ<vJ){var dja=uJ.X(null,wJ);$CLJS.wE(dja,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XD],null)]));wJ+=1}else{var xJ=$CLJS.y(tJ);if(xJ){var yJ=xJ;if($CLJS.wd(yJ)){var zJ=$CLJS.ic(yJ),eja=$CLJS.jc(yJ),fja=zJ,gja=$CLJS.D(zJ);tJ=eja;uJ=fja;vJ=gja}else{var hja=$CLJS.z(yJ);$CLJS.wE(hja,$CLJS.H([$CLJS.$r,$CLJS.oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XD],null)]));tJ=$CLJS.B(yJ);
uJ=null;vJ=0}wJ=0}else break}
for(var AJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rF,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.ur],null)],null),BJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.aF,$CLJS.uF,$CLJS.iF,$CLJS.bF],null)),CJ=null,DJ=0,EJ=0;;)if(EJ<DJ){var FJ=CJ.X(null,EJ);$CLJS.IF.v(FJ,$CLJS.$r,$CLJS.oj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,FJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.uD,AJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,
$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null)],null));EJ+=1}else{var GJ=$CLJS.y(BJ);if(GJ){var HJ=GJ;if($CLJS.wd(HJ)){var IJ=$CLJS.ic(HJ),ija=$CLJS.jc(HJ),jja=IJ,kja=$CLJS.D(IJ);BJ=ija;CJ=jja;DJ=kja}else{var JJ=$CLJS.z(HJ);$CLJS.IF.v(JJ,$CLJS.$r,$CLJS.oj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,JJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.uD,AJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.V,$CLJS.fE],null)],null));BJ=$CLJS.B(HJ);CJ=null;DJ=0}EJ=0}else break}
$CLJS.IF.v($CLJS.dB,$CLJS.$r,$CLJS.oj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.dB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.uD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Kk,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.ur],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.UD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.Qu,$CLJS.Vw,$CLJS.QE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$F],null)],null));$CLJS.IF.v($CLJS.HE,$CLJS.$r,$CLJS.oj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.HE],null),$CLJS.uD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.gD,$CLJS.oD],null)],null));
$CLJS.Y($CLJS.PI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.QI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.br,$CLJS.Hq,$CLJS.zF,$CLJS.RE,$CLJS.LE,$CLJS.Eq,$CLJS.Aq,$CLJS.Gq,$CLJS.Cq,$CLJS.KE,$CLJS.WE,$CLJS.ME,$CLJS.TE,$CLJS.iF,$CLJS.bF,$CLJS.aF,$CLJS.uF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RI,$CLJS.Gi],null)],null));