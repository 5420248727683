var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var DX,Ona,Pna,Qna,GX,Sna,Tna,Una,Vna,IX,JX,LX,NX,Wna,OX,PX,Xna,Yna,Zna,QX,Rna,RX,SX,$na,UX;DX=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.Xe(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
$CLJS.EX=function(a,b){return function(){function c(m,t,u){return new $CLJS.P(null,2,5,$CLJS.Q,[a.j?a.j(m,t,u):a.call(null,m,t,u),b.j?b.j(m,t,u):b.call(null,m,t,u)],null)}function d(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[a.g?a.g(m,t):a.call(null,m,t),b.g?b.g(m,t):b.call(null,m,t)],null)}function e(m){return new $CLJS.P(null,2,5,$CLJS.Q,[a.h?a.h(m):a.call(null,m),b.h?b.h(m):b.call(null,m)],null)}function f(){return new $CLJS.P(null,2,5,$CLJS.Q,[a.o?a.o():a.call(null),b.o?b.o():b.call(null)],null)}
var k=null,l=function(){function m(u,v,x,A){var C=null;if(3<arguments.length){C=0;for(var G=Array(arguments.length-3);C<G.length;)G[C]=arguments[C+3],++C;C=new $CLJS.w(G,0,null)}return t.call(this,u,v,x,C)}function t(u,v,x,A){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ne.M(a,u,v,x,A),$CLJS.Ne.M(b,u,v,x,A)],null)}m.A=3;m.B=function(u){var v=$CLJS.z(u);u=$CLJS.B(u);var x=$CLJS.z(u);u=$CLJS.B(u);var A=$CLJS.z(u);u=$CLJS.Hc(u);return t(v,x,A,u)};m.l=t;return m}();k=function(m,t,u,v){switch(arguments.length){case 0:return f.call(this);
case 1:return e.call(this,m);case 2:return d.call(this,m,t);case 3:return c.call(this,m,t,u);default:var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.l(m,t,u,x)}throw Error("Invalid arity: "+arguments.length);};k.A=3;k.B=l.B;k.o=f;k.h=e;k.g=d;k.j=c;k.l=l.l;return k}()};$CLJS.FX=function(a,b){return $CLJS.QK(a)(b,$CLJS.xf,$CLJS.xf)};
Ona=function(a,b,c){return $CLJS.cb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.ZE,b),$CLJS.Xr(2,2,c))};Pna=function(a,b){var c=$CLJS.Dl(a,$CLJS.FP);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Cb(c);b=$CLJS.hs.g?$CLJS.hs.g(c,b):$CLJS.hs.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.FP,b)}return a};Qna=function(a){return $CLJS.bf.g($CLJS.LU(a),$CLJS.MU(a))};
GX=function(a,b,c){var d=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Er,null,$CLJS.Rk,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.st(2,c):$CLJS.st(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?DX(2,c):DX(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.vW.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.vd(k)?$CLJS.Wf.j($CLJS.xf,$CLJS.jk.h($CLJS.Yk),l):$CLJS.td(k)?$CLJS.Jz($CLJS.Sa,l):l}):$CLJS.bk.g(a,f)};
Sna=function(a){return $CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return GX(b,Rna,d)},a,Qna(a))};
Tna=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.sK.h(a),b);a:{var d=c,e=$CLJS.ND;$CLJS.ND=!0;try{for(var f=$CLJS.xf;;){var k=$CLJS.z($CLJS.cf.g($CLJS.EX($CLJS.Ti,$CLJS.Mk),$CLJS.ff($CLJS.ik.j(HX,$CLJS.z,$CLJS.Mk),$CLJS.Ir.h($CLJS.FX($CLJS.kV,d)))));if($CLJS.n(k)){var l=k,m=$CLJS.I(l,0,null),t=$CLJS.I(l,1,null),u=GX(d,m,t);l=$CLJS.xh;var v=l.l,x=m;var A=$CLJS.n(x)?x:$CLJS.VK($CLJS.FX($CLJS.kV,d));var C=v.call(l,$CLJS.H([A]));console.warn("Clean: Removing bad clause due to error!",t,C,$CLJS.uV($CLJS.z($CLJS.Zs(d,
u))));if($CLJS.E.g(u,d)){var G=d;break a}l=u;var J=$CLJS.Yd.g(f,new $CLJS.P(null,2,5,$CLJS.Q,[m,t],null));d=l;f=J}else{G=d;break a}}}finally{$CLJS.ND=e}G=void 0}e=Sna(G);if($CLJS.E.g(c,e)){if($CLJS.E.g(b,$CLJS.D($CLJS.sK.h(a))-1))return a;b+=1}else a=$CLJS.lk.M(a,$CLJS.sK,$CLJS.R,b,e)}};Una=function(a){var b=$CLJS.HV();return $CLJS.kk.g(function(c){return $CLJS.E.g($CLJS.VD.h(c),"__join")?$CLJS.lk.j(c,$CLJS.VD,b):c},a)};
Vna=function(a){return"string"===typeof $CLJS.eO.h(a)?$CLJS.bk.g($CLJS.R.j(a,$CLJS.NU,$CLJS.FV($CLJS.eO.h(a))),$CLJS.eO):a};IX=function(){return $CLJS.jk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.he(b))?(b=$CLJS.de(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};JX=function(a){return $CLJS.Wf.j($CLJS.N,IX(),a)};
LX=function(a){return $CLJS.Be($CLJS.Wf.j($CLJS.N,$CLJS.ik.g(IX(),$CLJS.jk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.oi)})),$CLJS.Dd(a,$CLJS.KX)?$CLJS.bk.l(a,$CLJS.KX,$CLJS.H([$CLJS.tA])):a))};
NX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.h($CLJS.MX),c);a=$CLJS.E.g(a,$CLJS.SE)&&$CLJS.B(c)?$CLJS.Yd.g($CLJS.od(d),new $CLJS.h(null,1,[$CLJS.Oh,$CLJS.nd(d)],null)):d;b=$CLJS.Be(LX(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uL,a,b],null):a};Wna=function(a){return $CLJS.Wf.j($CLJS.xf,$CLJS.ik.g($CLJS.cf.h(function(b){return $CLJS.Ez(b,$CLJS.FA)}),$CLJS.cf.h($CLJS.MX)),$CLJS.BV.h(a))};
OX=function(a){a=$CLJS.O(a);a=$CLJS.K.g(a,$CLJS.sK);a=$CLJS.z($CLJS.cb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.MX.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.HP,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.RN,Wna(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.nU.h(c)],null)},null,a));return $CLJS.n($CLJS.iP.h(a))?$CLJS.yU(a,new $CLJS.h(null,1,[$CLJS.iP,$CLJS.FE],null)):a};
PX=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.K.g(a,b)),1)?$CLJS.SV(a,b,$CLJS.ik.g($CLJS.MX,$CLJS.z)):a;a=1<$CLJS.D($CLJS.K.g(a,b))?$CLJS.SV(d,b,function(e){return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lr],null),$CLJS.cf.h($CLJS.MX),e)}):d;return $CLJS.yU(a,$CLJS.Ae([b,c]))};Xna=function(a){var b=$CLJS.NU.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.bk.g(a,$CLJS.NU),$CLJS.eO,["card__",$CLJS.p.h(b)].join("")):a};
$CLJS.KX=new $CLJS.M("metabase.lib.query","transformation-added-base-type","metabase.lib.query/transformation-added-base-type",121891381);Yna=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);Zna=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);QX=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Rna=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);
RX=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);SX=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.TX=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);$na=new $CLJS.M(null,"m","m",1632677161);UX=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var HX,aoa,boa,coa,doa,eoa,foa,goa,hoa,ioa,joa;$CLJS.VX=$CLJS.N;$CLJS.WX=$CLJS.N;HX=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.hV,null,$CLJS.RD,null,$CLJS.DM,null,$CLJS.ZO,null,$CLJS.YE,null,$CLJS.NQ,null,$CLJS.ZE,null],null),null);aoa=$CLJS.Se($CLJS.N);boa=$CLJS.Se($CLJS.N);coa=$CLJS.Se($CLJS.N);doa=$CLJS.Se($CLJS.N);eoa=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.gB],null),$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.XX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.XA,eoa,aoa,boa,coa,doa);$CLJS.XX.m(null,$CLJS.Oh,function(a){if($CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.nd(a);var c=$CLJS.td(b);a=c?$CLJS.od(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.oV($CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.XX),d))}else b=a;return b});$CLJS.XX.m(null,$CLJS.gV,function(a){return a});
$CLJS.XX.m(null,$CLJS.UU,function(a){var b=$CLJS.XX.h($CLJS.ZE.h(a)),c=$CLJS.Be($CLJS.kk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.yV($CLJS.XX.h(t),u)},$CLJS.ZO.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.K.g(u,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.K.g(u,$CLJS.wD);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.WX,k=$CLJS.VX;$CLJS.WX=d;$CLJS.VX=e;try{var l=Ona(Vna(a),b,$CLJS.H([$CLJS.ZO,c])),m=$CLJS.cb(function(t,u){return $CLJS.Ra($CLJS.K.g(t,u))?t:$CLJS.lk.j(t,u,$CLJS.XX)},l,$CLJS.ck.l(HX,$CLJS.ZE,$CLJS.H([$CLJS.ZO])));return $CLJS.n($CLJS.DM.h(m))?$CLJS.lk.j(m,$CLJS.DM,Una):m}finally{$CLJS.VX=k,$CLJS.WX=f}});$CLJS.XX.m(null,$CLJS.aV,function(a){return Pna(a,function(b){return $CLJS.SV(b,$CLJS.CE,$CLJS.XX)})});
$CLJS.XX.m(null,$CLJS.nK,function(a){a=$CLJS.lk.j($CLJS.lk.j(a,$CLJS.vK,$CLJS.XX),$CLJS.sK,$CLJS.XX);var b=$CLJS.n($CLJS.RD.h(a))?$CLJS.lk.j(a,$CLJS.RD,function(c){return $CLJS.jl(c)?$CLJS.kk.g($CLJS.XX,c):$CLJS.mh.h(c)}):a;return $CLJS.Ra($CLJS.VD.h(a))?$CLJS.R.j(b,$CLJS.VD,"__join"):b});$CLJS.XX.m(null,$CLJS.nA,function(a){return $CLJS.kk.g($CLJS.XX,a)});
$CLJS.XX.m(null,$CLJS.pA,function(a){return $CLJS.n($CLJS.Ti.h(a))?Tna($CLJS.R.j($CLJS.lk.j($CLJS.CV(a),$CLJS.sK,function(b){return $CLJS.kk.g($CLJS.XX,b)}),$CLJS.TX,!0)):$CLJS.hs(a,$CLJS.XX)});$CLJS.XX.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.td(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.oV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,a,b],null))});
$CLJS.XX.m(null,$CLJS.fj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.yU(a,new $CLJS.h(null,3,[$CLJS.cC,$CLJS.tA,$CLJS.DN,$CLJS.uj,$CLJS.VQ,$CLJS.qD],null));var c=$CLJS.R.j;var d=$CLJS.oi.h(a);$CLJS.n(d)||(d=$CLJS.tA.h(a),d=$CLJS.n(d)?d:$CLJS.LD(b));a=c.call($CLJS.R,a,$CLJS.oi,d);return $CLJS.oV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fj,a,b],null))});
$CLJS.XX.m(null,$CLJS.SE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.Oh.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SE,$CLJS.bk.g(c,$CLJS.Oh),$CLJS.kk.g($CLJS.XX,b)],null);b=$CLJS.oV(b);return null!=a?$CLJS.Yd.g(b,$CLJS.XX.h(a)):b});$CLJS.XX.m(null,$CLJS.qA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.oV(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.XX.m(null,$CLJS.ZE,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.K.g($CLJS.WX,c);if(!$CLJS.n(f))throw $CLJS.Uh(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.YQ,a],null));return $CLJS.oV(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.XX.m(null,$CLJS.uL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.XX.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Ak.l($CLJS.H([c,a]))],null),d)});foa=$CLJS.Se($CLJS.N);goa=$CLJS.Se($CLJS.N);hoa=$CLJS.Se($CLJS.N);ioa=$CLJS.Se($CLJS.N);joa=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.gB],null),$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.MX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.XA,joa,foa,goa,hoa,ioa);
$CLJS.MX.m(null,$CLJS.Oh,function(a){if($CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.td(a)){c=$CLJS.Wf.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.MX,d);a=LX(a);a=c.call($CLJS.Wf,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.cf.g($CLJS.MX,$CLJS.ae(a,d)))}else a=$CLJS.td(a)?$CLJS.hs(JX(a),$CLJS.MX):
a;return a});for(var YX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[UX,SX],null)),ZX=null,$X=0,aY=0;;)if(aY<$X){var koa=ZX.X(null,aY);$CLJS.pE(koa,RX);aY+=1}else{var bY=$CLJS.y(YX);if(bY){var cY=bY;if($CLJS.wd(cY)){var dY=$CLJS.ic(cY),loa=$CLJS.jc(cY),moa=dY,noa=$CLJS.D(dY);YX=loa;ZX=moa;$X=noa}else{var ooa=$CLJS.z(cY);$CLJS.pE(ooa,RX);YX=$CLJS.B(cY);ZX=null;$X=0}aY=0}else break}
for(var eY=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Rw,$CLJS.dF,$CLJS.sF,$CLJS.OE,$CLJS.Dj,$CLJS.wF,$CLJS.si,$CLJS.VE,$CLJS.qF,$CLJS.xF,$CLJS.vF,$CLJS.CF],null)),fY=null,gY=0,hY=0;;)if(hY<gY){var poa=fY.X(null,hY);$CLJS.pE(poa,UX);hY+=1}else{var iY=$CLJS.y(eY);if(iY){var jY=iY;if($CLJS.wd(jY)){var kY=$CLJS.ic(jY),qoa=$CLJS.jc(jY),roa=kY,soa=$CLJS.D(kY);eY=qoa;fY=roa;gY=soa}else{var toa=$CLJS.z(jY);$CLJS.pE(toa,UX);eY=$CLJS.B(jY);fY=null;gY=0}hY=0}else break}
for(var lY=$CLJS.y($CLJS.tf([$CLJS.Jq,$CLJS.$r,$CLJS.Kq,$CLJS.DF,$CLJS.SE,$CLJS.mF,$CLJS.JE,$CLJS.FF,$CLJS.AE,$CLJS.XE,$CLJS.EE,$CLJS.hF,$CLJS.oF,$CLJS.GE,$CLJS.ZA,$CLJS.$A,$CLJS.Fx,$CLJS.XH,$CLJS.bI,$CLJS.RH,$CLJS.aB,$CLJS.WH,$CLJS.PH,$CLJS.eI,$CLJS.jI,$CLJS.gI,$CLJS.VH,$CLJS.lI,$CLJS.bB,$CLJS.eB,$CLJS.IE,$CLJS.BE,$CLJS.GF,$CLJS.XG,$CLJS.lF,$CLJS.Pw,$CLJS.yF,$CLJS.AF,$CLJS.HF,$CLJS.ks,$CLJS.qs],!0)),mY=null,nY=0,oY=0;;)if(oY<nY){var uoa=mY.X(null,oY);$CLJS.pE(uoa,SX);oY+=1}else{var pY=$CLJS.y(lY);
if(pY){var qY=pY;if($CLJS.wd(qY)){var rY=$CLJS.ic(qY),voa=$CLJS.jc(qY),woa=rY,xoa=$CLJS.D(rY);lY=voa;mY=woa;nY=xoa}else{var yoa=$CLJS.z(qY);$CLJS.pE(yoa,SX);lY=$CLJS.B(qY);mY=null;nY=0}oY=0}else break}$CLJS.MX.m(null,RX,function(a){return NX(a)});$CLJS.MX.m(null,$CLJS.pA,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.ik.g(IX(),$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.MX.h(b)],null)})),a)});
$CLJS.MX.m(null,$CLJS.ZE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.td(b))try{var d=LX(b);b=$CLJS.Q;var e=$CLJS.VX,f=$CLJS.K.j(e,c,QX);if($CLJS.E.g(f,QX))throw $CLJS.Uh(["Unable to find key ",$CLJS.xh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[$na,e,$CLJS.Vv,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.ZE,f],null);return $CLJS.y(d)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uL,k,d],null):k}catch(l){throw c=l,d=$CLJS.sU(c),d=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.Th(d,new $CLJS.h(null,1,[$CLJS.V,a],null),c);}else return a});$CLJS.MX.m(null,$CLJS.nA,function(a){return $CLJS.kk.g($CLJS.MX,a)});
$CLJS.MX.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.td(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,$CLJS.MX.h(a),LX(b)],null)});
$CLJS.MX.m(null,$CLJS.fj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=LX($CLJS.yU(b,new $CLJS.h(null,3,[$CLJS.tA,$CLJS.cC,$CLJS.uj,$CLJS.DN,$CLJS.qD,$CLJS.VQ],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fj,a,b],null)});
$CLJS.MX.m(null,$CLJS.nK,function(a){var b=JX(a);a=0==$CLJS.VD.h(a).lastIndexOf("__join",0)?$CLJS.bk.g(b,$CLJS.VD):b;return $CLJS.Ak.l($CLJS.H([$CLJS.hs($CLJS.bk.l(a,$CLJS.sK,$CLJS.H([$CLJS.vK])),$CLJS.MX),PX($CLJS.Cl(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vK],null)),$CLJS.vK,$CLJS.PQ),OX(a)]))});
$CLJS.MX.m(null,$CLJS.UU,function(a){var b=$CLJS.ZE.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.K.g(k,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.K.g(k,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.WX,e=$CLJS.VX;$CLJS.WX=c;$CLJS.VX=b;try{return $CLJS.cb(function(f,k){return $CLJS.SV(f,
k,$CLJS.MX)},PX($CLJS.SV($CLJS.SV(Xna(JX(a)),$CLJS.ZE,function(f){return $CLJS.kk.g(NX,f)}),$CLJS.ZO,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.wd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.id(u,A),G=$CLJS.MX.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xV(C),$CLJS.E.g($CLJS.fj,$CLJS.z(G))?$CLJS.dd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),
m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);u=$CLJS.MX.h(x);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xV(x),$CLJS.E.g($CLJS.fj,$CLJS.z(u))?$CLJS.dd(u):u],null),m($CLJS.Hc(t)))}return null}},null,null)}(f)}())}),$CLJS.hV,$CLJS.NE),$CLJS.ck.l(HX,$CLJS.ZE,$CLJS.H([$CLJS.hV,$CLJS.ZO])))}finally{$CLJS.VX=e,$CLJS.WX=d}});$CLJS.MX.m(null,$CLJS.aV,function(a){return $CLJS.hs(JX(a),$CLJS.MX)});
$CLJS.MX.m(null,$CLJS.gV,function(a){try{var b=JX(a),c=$CLJS.uM.h(b),d=OX(b),e=$CLJS.E.g($CLJS.gj.h($CLJS.fd($CLJS.sK.h(a))),$CLJS.aV)?$CLJS.iP:$CLJS.FE;return $CLJS.Ak.l($CLJS.H([$CLJS.bk.l(b,$CLJS.sK,$CLJS.H([$CLJS.uM,$CLJS.TX])),function(){var k=$CLJS.Ae([$CLJS.Ti,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.uM,c):k}()]))}catch(k){var f=k;throw $CLJS.Th(function(){var l=$CLJS.sU(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.FE,a],null),f);}});
$CLJS.sY=function(){function a(d,e,f){f=$CLJS.CX(null,$CLJS.Ly(f,$CLJS.H([$CLJS.ji,!0])));var k=$CLJS.EV(d,e);k=$CLJS.O(k);var l=$CLJS.K.g(k,$CLJS.ZE);k=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.K.g(x,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.K.g(x,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.WX,t=$CLJS.VX;$CLJS.WX=k;$CLJS.VX=l;try{try{return $CLJS.XX.h(f)}catch(v){var u=v;throw $CLJS.Th(function(){var x=$CLJS.sU(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.FE,d,$CLJS.GK,e,Zna,f,Yna,$CLJS.WX],null),u);}}finally{$CLJS.VX=t,$CLJS.WX=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();