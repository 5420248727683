var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Wqa,Xqa,Yqa,Zqa,k3,l3,m3,n3,$qa,ara,bra,cra,dra,era,fra,gra,hra,x3,y3,ira,jra,kra,w3,v3,lra,z3;$CLJS.e3=function(a,b){$CLJS.cb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Wqa=function(a){return function(b){var c=$CLJS.Ve(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.dl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};Xqa=function(a,b){return function f(d,e){return new $CLJS.ie(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Dd(k,t))l=$CLJS.Hc(m);else{k=$CLJS.ae(l,f($CLJS.Hc(m),$CLJS.Yd.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.Sg)};
Yqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Ra($CLJS.y($CLJS.Bz(c,d)))?c:$CLJS.Ne.M($CLJS.vW,c,d,e,b)};$CLJS.f3=function(a){return(0,$CLJS.ma)($CLJS.Gz(a,$CLJS.Wma,""))};$CLJS.g3=function(a,b){return $CLJS.s0.g(a,b instanceof $CLJS.M?b:$CLJS.di.h(b))};
$CLJS.h3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.kk.g($CLJS.pV,d);var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h(function(f){return $CLJS.U1.v(a,b,f,c)}),d);return $CLJS.kk.g(function(f){return $CLJS.R.j(f,$CLJS.LY,$CLJS.Dd(e,f))},c)}return null};$CLJS.i3=function(a){return $CLJS.E.g($CLJS.XA(a),$CLJS.nK)};Zqa=function(a,b){return $CLJS.n($CLJS.RD.h(a))?$CLJS.lk.j(a,$CLJS.RD,function(c){return $CLJS.sd(c)?$CLJS.kk.g(function(d){return $CLJS.j3.g?$CLJS.j3.g(d,b):$CLJS.j3.call(null,d,b)},c):c}):a};
k3=function(a){if($CLJS.n(a)){var b=$CLJS.ff;a:try{if($CLJS.vd(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.vd(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.ce(d,$CLJS.jF))try{var e=$CLJS.F(a,3);if($CLJS.vd(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.ce(f,$CLJS.jF)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Z){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.z($CLJS.y(b($CLJS.Sa,k)))}else a=null;return a};l3=function(a){if($CLJS.n(k3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};m3=function(a){return $CLJS.n(k3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
n3=function(a,b){var c=$CLJS.j3;if($CLJS.Ra(k3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Ne.j(c,a,b)],null)};
$qa=function(a,b,c){return $CLJS.pd($CLJS.vK.h(a))?a:$CLJS.n(b)?Yqa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vK],null),function(d){return function l(f,k){try{if($CLJS.vd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ce(m,$CLJS.jF))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.yc===t.mf||(t.C?0:$CLJS.Ya($CLJS.ub,t)):$CLJS.Ya($CLJS.ub,t))try{var u=$CLJS.K.j(t,$CLJS.tO,$CLJS.CU);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.j3.g?$CLJS.j3.g(k,c):$CLJS.j3.call(null,k,c);throw $CLJS.Z;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.XV(l,f,k);throw v;}throw x;}}($CLJS.xf,d)}):$CLJS.lk.j(a,$CLJS.vK,function(d){return $CLJS.kk.g(function(e){return n3(e,$CLJS.H([c]))},d)})};
$CLJS.j3=function(a,b){var c=$CLJS.XA(a);switch(c instanceof $CLJS.M?c.S:null){case "field":return $CLJS.FD(a,$CLJS.DD,$CLJS.H([$CLJS.tO,b]));case "metadata/column":return $CLJS.DD(a,$CLJS.dZ,b);case "mbql/join":return c=$CLJS.i1(a),$qa(Zqa($CLJS.DD(a,$CLJS.VD,b),b),c,b);default:return b=$CLJS.Mz($CLJS.Cx),$CLJS.n($CLJS.Lz("metabase.lib.join",b))&&$CLJS.Kz("metabase.lib.join",b,$CLJS.xv.l($CLJS.H(["with-join-value should not be called with",$CLJS.xh.l($CLJS.H([a]))])),null),a}};
$CLJS.o3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.zz($CLJS.vY,$CLJS.T)(c);a=$CLJS.j1($CLJS.VD.h(a),$CLJS.zz($CLJS.vY,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.vY,e,$CLJS.H([$CLJS.KY,b]))};ara=function(a,b){return $CLJS.Ra(b)?a:$CLJS.kk.g(function(c){var d=m3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.i1(d))?null:n3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.p3=function(a,b){b=ara($CLJS.kk.g($CLJS.qV,b),$CLJS.i1(a));return $CLJS.DD(a,$CLJS.vK,$CLJS.Be(b))};
$CLJS.q3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.xf)?$CLJS.cz:$CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ik.g($CLJS.cf.h($CLJS.pV),function(){var c=$CLJS.i1(a);return $CLJS.n(c)?$CLJS.cf.h(function(d){return $CLJS.j3(d,c)}):$CLJS.Pd}()),b));return $CLJS.DD(a,$CLJS.RD,b)};
bra=function(a,b){b=$CLJS.Al(function(d){return $CLJS.U1.g(d,a)},b);var c=$CLJS.sG($CLJS.zz($CLJS.JZ,$CLJS.KZ),b);if($CLJS.n(c))return c;b=$CLJS.z(b);if($CLJS.n(b))return b;b=$CLJS.sG($CLJS.JZ,a);if($CLJS.n(b))return b;b=$CLJS.sG($CLJS.KZ,a);return $CLJS.n(b)?b:$CLJS.z(a)};cra=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.Gz(a,/ id$/i,"")):null};
dra=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.uh(function(){return $CLJS.p2(b)});var d=new $CLJS.uh(function(){return $CLJS.p2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
era=function(a,b,c){var d=$CLJS.k_.g($CLJS.E.g($CLJS.gj.h(b),$CLJS.gV)?b:a,b),e=$CLJS.n(c)?cra($CLJS.k_.g(a,c)):null,f=dra(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Ra($CLJS.dh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
fra=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.H1(l,$CLJS.jF)&&$CLJS.Ad($CLJS.U1.v(a,b,l,e)))return $CLJS.j3(l,d);throw $CLJS.Z;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Z)return $CLJS.XV(m,k,l);throw t;}throw u;}}($CLJS.xf,c)};
gra=function(a,b,c,d,e,f){c=fra(a,b,c,d,f);return function t(l,m){try{if($CLJS.vd(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.H1(u,$CLJS.jF))try{var v=$CLJS.F(m,3);if($CLJS.H1(v,$CLJS.jF)){var x=$CLJS.F(m,3),A=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),J=$CLJS.i1(A),S=$CLJS.i1(x);if($CLJS.Ra($CLJS.n(J)?J:S))return $CLJS.n($CLJS.U1.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.j3(A,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,$CLJS.j3(x,d)],null);if($CLJS.E.g(J,d)&&$CLJS.E.g(S,d)){var X=
$CLJS.FD(A,$CLJS.bk,$CLJS.H([$CLJS.tO])),W=$CLJS.FD(x,$CLJS.bk,$CLJS.H([$CLJS.tO])),da=null==$CLJS.U1.v(a,b,X,e);var ra=da?$CLJS.U1.v(a,b,W,e):da;return $CLJS.n(ra)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,W],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,X,x],null)}return m}throw $CLJS.Z;}catch(sb){if(sb instanceof Error){var Ma=sb;if(Ma===$CLJS.Z)throw $CLJS.Z;throw Ma;}throw sb;}else throw $CLJS.Z;}catch(sb){if(sb instanceof Error){Ma=sb;if(Ma===$CLJS.Z)throw $CLJS.Z;throw Ma;}throw sb;}else throw $CLJS.Z;
}catch(sb){if(sb instanceof Error){Ma=sb;if(Ma===$CLJS.Z)return $CLJS.XV(t,l,m);throw Ma;}throw sb;}}($CLJS.xf,c)};hra=function(a,b){var c=$CLJS.HV();$CLJS.e3(c,b);return c(a)};
$CLJS.r3=function(a,b,c){if($CLJS.Dd(c,$CLJS.VD))return c;var d=$CLJS.EV(a,b),e=$CLJS.X_.j(a,b,d),f=$CLJS.y($CLJS.ff($CLJS.Sa,function v(t,u){try{if($CLJS.vd(u)&&1<=$CLJS.D(u))try{var x=$CLJS.pk.j(u,0,1);if($CLJS.vd(x)&&1===$CLJS.D(x))try{var A=$CLJS.F(x,0);if($CLJS.ce(A,$CLJS.jF))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Z)throw $CLJS.Z;throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)throw $CLJS.Z;
throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)return $CLJS.zU(v,t,u);throw C;}throw G;}}($CLJS.xf,$CLJS.vK.h(c))));f=bra(e,f);var k=hra(era(a,c,f),$CLJS.Al($CLJS.VD,$CLJS.DM.h(d))),l=$CLJS.V_.h($CLJS.c1.g(a,$CLJS.sK.h(c)));return $CLJS.j3($CLJS.lk.j(c,$CLJS.vK,function(t){return $CLJS.kk.g(function(u){return gra(a,b,u,k,e,l)},t)}),k)};$CLJS.s3=function(a){return $CLJS.vK.h(a)};
$CLJS.t3=function(a){return $CLJS.Ak.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.gj,$CLJS.wK,$CLJS.BD,a],null),$CLJS.E.g(a,$CLJS.$D)?new $CLJS.h(null,1,[$CLJS.Oh,!0],null):null]))};$CLJS.u3=function(a,b){b=$CLJS.z($CLJS.sK.h(b));return $CLJS.n($CLJS.NU.h(b))?$CLJS.e_(a,$CLJS.NU.h(b)):$CLJS.n($CLJS.eO.h(b))?$CLJS.c_(a,$CLJS.eO.h(b)):null};
x3=function(a){a=$CLJS.rG(function(d){return $CLJS.KZ(d)?v3:$CLJS.JZ(d)?w3:$CLJS.Ss},a);var b=$CLJS.O(a);a=$CLJS.K.g(b,v3);var c=$CLJS.K.g(b,w3);b=$CLJS.K.g(b,$CLJS.Ss);return $CLJS.bf.l(a,c,$CLJS.H([b]))};y3=function(a,b,c,d){return $CLJS.Ra(c)?d:$CLJS.kk.g(function(e){return $CLJS.n($CLJS.LY.h(e))?$CLJS.g3(e,$CLJS.n2(c)):e},$CLJS.h3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
ira=function(a,b,c,d){var e=new $CLJS.uh(function(){return $CLJS.X_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.A_,!1,$CLJS.I_,!1],null))});return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ul(function(f){var k=$CLJS.O(f),l=$CLJS.K.g(k,$CLJS.ZJ);return $CLJS.n(function(){var m=$CLJS.JZ(k);return m?l:m}())?(f=$CLJS.sG(function(m){return $CLJS.E.g(l,$CLJS.Ii.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,z3,f):null):null}),$CLJS.X_.v(a,b,c,new $CLJS.h(null,2,[$CLJS.A_,!1,$CLJS.I_,!1],null))))};jra={};
kra=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);w3=new $CLJS.M(null,"fk","fk",398442651);v3=new $CLJS.M(null,"pk","pk",-771936732);lra=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);z3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.L_.m(null,$CLJS.nK,function(a,b,c){b=$CLJS.O(c);b=$CLJS.K.g(b,$CLJS.sK);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.K.g(b,$CLJS.eO);b=$CLJS.K.g(b,$CLJS.NU);c=$CLJS.n(c)?$CLJS.jD.h($CLJS.c_(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.e_(a,b),a=$CLJS.n(c)?$CLJS.k_.j(a,0,c):$CLJS.n1(b)):a=null;return $CLJS.n(a)?a:$CLJS.ID("Native Query")});$CLJS.S_.m(null,$CLJS.nK,function(a,b,c){a=$CLJS.k_.j(a,b,c);c=$CLJS.VD.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.jD,a],null)});
$CLJS.R_.m(null,$CLJS.nK,function(){throw $CLJS.Uh("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.L_.m(null,$CLJS.wK,function(a,b,c){a=$CLJS.O(c);a=$CLJS.K.g(a,$CLJS.BD);a=a instanceof $CLJS.M?a.S:null;switch(a){case "left-join":return $CLJS.ID("Left outer join");case "right-join":return $CLJS.ID("Right outer join");case "inner-join":return $CLJS.ID("Inner join");case "full-join":return $CLJS.ID("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.S_.m(null,$CLJS.wK,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.K.g(d,$CLJS.BD);c=$CLJS.K.g(d,$CLJS.Oh);a=new $CLJS.h(null,2,[$CLJS.J_,$CLJS.$z(e),$CLJS.jD,$CLJS.k_.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.Oh,!0):a});
$CLJS.U_.m(null,$CLJS.nK,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.K.g(e,$CLJS.VD),k=$CLJS.K.j(e,$CLJS.RD,$CLJS.cz),l=$CLJS.K.g(e,$CLJS.sK);c=$CLJS.O(d);var m=$CLJS.K.g(c,$CLJS.G_);if($CLJS.E.g(k,$CLJS.cz))return null;var t="undefined"!==typeof $CLJS.fL&&"undefined"!==typeof jra&&"undefined"!==typeof $CLJS.mra&&"undefined"!==typeof $CLJS.A3?new $CLJS.Cc(function(){return $CLJS.A3},$CLJS.ld(lra,new $CLJS.h(null,1,[$CLJS.yj,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.sK,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.wx)?$CLJS.V_.v(u,-1,$CLJS.nd(l),c):function(){return function A(x){return new $CLJS.ie(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.wd(C)){var G=$CLJS.ic(C),J=$CLJS.D(G),S=$CLJS.le(J);a:for(var X=0;;)if(X<J){var W=$CLJS.id(G,X);W=$CLJS.FD(W,$CLJS.bk,$CLJS.H([$CLJS.tO]));W=$CLJS.i_.j(u,-1,W);S.add(W);X+=1}else{G=!0;break a}return G?$CLJS.oe($CLJS.qe(S),A($CLJS.jc(C))):$CLJS.oe($CLJS.qe(S),null)}S=$CLJS.z(C);S=$CLJS.FD(S,$CLJS.bk,$CLJS.H([$CLJS.tO]));
return $CLJS.ae($CLJS.i_.j(u,-1,S),A($CLJS.Hc(C)))}return null}},null,null)}(k)}();return $CLJS.kk.g(function(v){v=$CLJS.R.j(v,$CLJS.YY,f);v=$CLJS.j3($CLJS.R.l(v,$CLJS.jD,$CLJS.k_.j(a,b,v),$CLJS.H([$CLJS.NJ,$CLJS.HY])),f);return $CLJS.o3(e,m,v)},c)});$CLJS.W_.m(null,$CLJS.nK,function(a,b,c,d){return $CLJS.V_.v(a,b,$CLJS.R.j(c,$CLJS.RD,$CLJS.wx),d)});
var B3,nra=$CLJS.Se($CLJS.N),ora=$CLJS.Se($CLJS.N),pra=$CLJS.Se($CLJS.N),qra=$CLJS.Se($CLJS.N),rra=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.gB],null),$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));B3=new $CLJS.Ph($CLJS.qh.g("metabase.lib.join","join-clause-method"),$CLJS.XA,rra,nra,ora,pra,qra);B3.m(null,$CLJS.nK,function(a){return a});B3.m(null,$CLJS.gV,function(a){return $CLJS.oV(new $CLJS.h(null,2,[$CLJS.gj,$CLJS.nK,$CLJS.sK,$CLJS.sK.h($CLJS.CV(a))],null))});
B3.m(null,$CLJS.UU,function(a){return $CLJS.oV(new $CLJS.h(null,2,[$CLJS.gj,$CLJS.nK,$CLJS.sK,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});B3.m(null,$CLJS.jK,function(a){return $CLJS.oV(new $CLJS.h(null,2,[$CLJS.gj,$CLJS.nK,$CLJS.sK,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.NU,$CLJS.Ii.h(a),$CLJS.gj,$CLJS.UU],null)],null)],null))});
B3.m(null,$CLJS.TJ,function(a){var b=$CLJS.O(a),c=$CLJS.K.g(b,$CLJS.dZ);a=$CLJS.K.g(b,kra);b=B3.h(new $CLJS.h(null,3,[$CLJS.gj,$CLJS.UU,$CLJS.uA,new $CLJS.h(null,1,[$CLJS.wD,$CLJS.p.h($CLJS.HD())],null),$CLJS.eO,$CLJS.Ii.h(b)],null));c=$CLJS.n(c)?$CLJS.j3(b,c):b;return $CLJS.n(a)?$CLJS.q3.g?$CLJS.q3.g(c,a):$CLJS.q3.call(null,c,a):c});
$CLJS.C3=function(){function a(d,e){return $CLJS.p3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.F1(B3.h(d),$CLJS.RD,$CLJS.wx)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.sra=function(){function a(d,e,f){var k=$CLJS.C3.h(f);f=$CLJS.pd($CLJS.s3.h?$CLJS.s3.h(k):$CLJS.s3.call(null,k))?function(){var m=$CLJS.u3.g?$CLJS.u3.g(d,k):$CLJS.u3.call(null,d,k);return $CLJS.D3.j?$CLJS.D3.j(d,e,m):$CLJS.D3.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.p3(k,f):k;var l=$CLJS.r3(d,e,f);return $CLJS.PV.l(d,e,$CLJS.lk,$CLJS.H([$CLJS.DM,function(m){return $CLJS.Yd.g($CLJS.yf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.E3=function(){function a(d,e){return $CLJS.Be($CLJS.K.g($CLJS.EV(d,e),$CLJS.DM))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.tra=function(){function a(d){d=$CLJS.Y0(d);d=$CLJS.dz.h(d);return $CLJS.Wf.j($CLJS.xf,$CLJS.ik.g($CLJS.ef($CLJS.Qe($CLJS.Dd,d)),$CLJS.cf.h($CLJS.t3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$D,$CLJS.QD,$CLJS.dE,$CLJS.gE],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.ura=function(){function a(d,e,f,k){var l=$CLJS.i3(f)?$CLJS.i1(f):null,m=$CLJS.Wf.j($CLJS.Sg,$CLJS.ik.g($CLJS.cf.h($CLJS.i1),Wqa(function(t){return $CLJS.hk.g(t,l)})),$CLJS.E3.g(d,e));f=$CLJS.n(k)?k:$CLJS.i3(f)?l3($CLJS.z($CLJS.s3(f))):null;return x3(y3(d,e,f,$CLJS.jk.g(function(t){t=$CLJS.i1(t);return $CLJS.n(t)?$CLJS.Dd(m,t):null},$CLJS.X_.v(d,e,$CLJS.EV(d,e),new $CLJS.h(null,1,[$CLJS.A_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();
$CLJS.vra=function(){function a(d,e,f,k,l){k=$CLJS.i3(f)?$CLJS.u3(d,f):f;var m=$CLJS.i3(f)?$CLJS.i1(f):null;f=$CLJS.n(l)?l:$CLJS.i3(f)?m3($CLJS.z($CLJS.s3(f))):null;f=$CLJS.n(f)?$CLJS.Ra(m)?$CLJS.FD(f,$CLJS.bk,$CLJS.H([$CLJS.tO])):f:null;return x3(y3(d,e,f,$CLJS.cf.g(function(t){t=$CLJS.R.j(t,$CLJS.NJ,$CLJS.HY);return $CLJS.n(m)?$CLJS.j3(t,m):t},$CLJS.X_.v(d,e,k,new $CLJS.h(null,1,[$CLJS.A_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();$CLJS.wra=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.$1}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.$1};return b}();
$CLJS.D3=function(){function a(d,e,f){function k(u,v){u=$CLJS.bk.g(u,$CLJS.p1);v=$CLJS.bk.g(v,$CLJS.p1);return $CLJS.c3.l($CLJS.X1.h($CLJS.Hq),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Be(Xqa(function(x){return $CLJS.Ii.h(z3.h(x))},ira(d,e,u,v)))}var m=$CLJS.EV(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.kk.g(function(v){return k(v,z3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.kk.g(function(u){return k(z3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.xra=$CLJS.cf.h(function(a){return $CLJS.R.j(a,$CLJS.NJ,$CLJS.HY)});
$CLJS.yra=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.i3(k)?l3($CLJS.z($CLJS.s3(k))):null;$CLJS.n(l)?(l=$CLJS.n_.j(e,f,l),l=$CLJS.Bz(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,$CLJS.jD],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.DV(e,f)){l=$CLJS.E3.g(e,f);var m=$CLJS.pd(l);k=m?m:$CLJS.i3(k)?$CLJS.E.g($CLJS.VD.h(k),$CLJS.VD.h($CLJS.z(l))):null;k=$CLJS.n(k)?$CLJS.YZ(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.YZ(e),k=$CLJS.c_(e,k),e=$CLJS.k_.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.ID("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.zra=function(){function a(d,e,f,k){f=$CLJS.qV.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.di.h(k);e=$CLJS.DV(d,e);l=$CLJS.w0.j(d,e,l);d=$CLJS.w0.j(d,e,m);m=null==k||$CLJS.Dd($CLJS.Wg($CLJS.cf.g($CLJS.di,l)),k);d=null==k||$CLJS.Dd($CLJS.Wg($CLJS.cf.g($CLJS.di,d)),k);f=m?$CLJS.lk.v(f,2,$CLJS.g3,k):f;return d?$CLJS.lk.v(f,3,$CLJS.g3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.O_.m(null,$CLJS.DM,function(a,b){var c=$CLJS.Be($CLJS.E3.g(a,b));c=null==c?null:$CLJS.cf.g(function(d){return $CLJS.k_.j(a,b,d)},c);return null==c?null:$CLJS.is(" + ",c)});