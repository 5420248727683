var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Wga,Xga,Yga,Zga,$ga,aha,bha,NF,cha,dha,eha,fha,gha,hha,iha,jha,PF,kha;$CLJS.JF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Wga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.KF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Xga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Yga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.LF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);Zga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.MF=new $CLJS.M(null,"display-info","display-info",-816930907);$ga=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);aha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
bha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);NF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);cha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);dha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);eha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);fha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
gha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);hha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);iha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.OF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);jha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);PF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.QF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);kha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.yE($CLJS.Rw,$CLJS.H([$CLJS.$r,$CLJS.$i,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XD],null)],null)],null)],null)]));$CLJS.yE($CLJS.kF,$CLJS.H([$CLJS.$r,$CLJS.$i,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XD],null)],null)],null)],null)]));
$CLJS.wE($CLJS.dF,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)]));$CLJS.wE($CLJS.OE,$CLJS.H([$CLJS.$r,$CLJS.$i,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XD],null)],null)]));$CLJS.wE($CLJS.sF,$CLJS.H([$CLJS.$r,$CLJS.$i,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)],null)]));
$CLJS.wE($CLJS.Dj,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null)],null)]));$CLJS.pE($CLJS.Dj,$CLJS.bE);$CLJS.wE($CLJS.wF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)]));$CLJS.pE($CLJS.wF,$CLJS.bE);$CLJS.wE($CLJS.si,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cE],null)],null)]));$CLJS.pE($CLJS.si,$CLJS.bE);
$CLJS.Y(NF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.us,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.wE($CLJS.VE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,NF],null)]));$CLJS.pE($CLJS.VE,$CLJS.bE);
$CLJS.wE($CLJS.qF,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)],null)]));$CLJS.wE($CLJS.xF,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)]));$CLJS.wE($CLJS.vF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)]));
$CLJS.wE($CLJS.EF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)]));$CLJS.pE($CLJS.vF,$CLJS.bE);$CLJS.pE($CLJS.EF,$CLJS.bE);$CLJS.wE($CLJS.CF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)],null)]));$CLJS.pE($CLJS.CF,$CLJS.bE);
$CLJS.wE($CLJS.PE,$CLJS.H([$CLJS.$r,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)]));$CLJS.Y(PF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.er,jha,hha,aha,Xga,Wga,$ga,bha,cha,dha,gha,eha,kha,iha,Yga,fha,$CLJS.al],null));$CLJS.Y($CLJS.KF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cj,new $CLJS.h(null,1,[$CLJS.si,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,PF],null)],null));
$CLJS.RF=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.cF,$CLJS.Rw,$CLJS.LF,!1,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.ID("Count of rows"),$CLJS.UE,$CLJS.ID("Count"),$CLJS.Is,$CLJS.ID("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.vF,$CLJS.JF,$CLJS.Aj,$CLJS.LF,!0,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.ID("Sum of ..."),$CLJS.UE,$CLJS.ID("Sum"),$CLJS.Is,$CLJS.ID("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.dF,$CLJS.JF,$CLJS.Aj,$CLJS.LF,!0,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.ID("Average of ..."),$CLJS.UE,$CLJS.ID("Average"),$CLJS.Is,$CLJS.ID("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.wF,$CLJS.JF,$CLJS.Aj,$CLJS.LF,!0,$CLJS.OF,$CLJS.fF,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.ID("Median of ..."),$CLJS.UE,$CLJS.ID("Median"),$CLJS.Is,$CLJS.ID("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.OE,$CLJS.JF,$CLJS.vr,$CLJS.LF,!0,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.ID("Number of distinct values of ..."),$CLJS.UE,$CLJS.ID("Distinct values"),$CLJS.Is,$CLJS.ID("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.EF,$CLJS.JF,$CLJS.Aj,$CLJS.LF,!0,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.ID("Cumulative sum of ..."),
$CLJS.UE,$CLJS.ID("Sum"),$CLJS.Is,$CLJS.ID("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.cF,$CLJS.kF,$CLJS.LF,!1,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.ID("Cumulative count of rows"),$CLJS.UE,$CLJS.ID("Count"),$CLJS.Is,$CLJS.ID("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.xF,$CLJS.JF,$CLJS.Aj,$CLJS.LF,
!0,$CLJS.OF,$CLJS.tF,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.ID("Standard deviation of ..."),$CLJS.UE,$CLJS.ID("SD"),$CLJS.Is,$CLJS.ID("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.si,$CLJS.JF,$CLJS.Hi,$CLJS.LF,!0,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.ID("Minimum of ..."),$CLJS.UE,$CLJS.ID("Min"),$CLJS.Is,$CLJS.ID("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.cF,$CLJS.Dj,$CLJS.JF,$CLJS.Hi,$CLJS.LF,!0,$CLJS.OF,$CLJS.DE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.ID("Maximum of ..."),$CLJS.UE,$CLJS.ID("Max"),$CLJS.Is,$CLJS.ID("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(Zga,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.QF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF,$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.br],null),$CLJS.cf.h($CLJS.cF),$CLJS.RF)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,new $CLJS.h(null,1,[$CLJS.zr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.Gi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,$CLJS.ur],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.OF,$CLJS.Gi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MF,$CLJS.jd],null)],null));